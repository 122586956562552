import React from "react"
import { withPrefix } from "gatsby"
import Icon from "../Icon/Icon"
import Button from "../Button/Button"

class ResponsiveSidebar extends React.Component {
  componentDidMount() {
    let responsiveSidebar = document.createElement("script")
    responsiveSidebar.src = `${withPrefix(
      "scripts/responsive-sidebar-script.js"
    )}`
    responsiveSidebar.id = "responsive-sidebar-script-js"
    document.body.appendChild(responsiveSidebar)
  }

  componentWillUnmount() {
    document.getElementById("responsive-sidebar-script-js").remove()
  }

  render() {
    return (
      <>
        <div className="hide@md no-js:is-hidden">
          <Button
            btnType="primary"
            btnAriaControlId="sidebar"
            btnContent="Menú"
          />
        </div>
        <aside
          className="sidebar sidebar--static@md js-sidebar "
          data-static-class="sidebar--sticky-on-desktop z-index-1 bg-key-l-6"
          id="sidebar"
          aria-labelledby="Menú lateral"
        >
          <div className="sidebar__panel">
            <header className="sidebar__header z-index-2">
              <h1 className="text-md text-truncate" id="sidebarTitle">
                {this.props.title}
              </h1>

              <button className="reset sidebar__close-btn js-sidebar__close-btn js-tab-focus">
                <Icon iconName="icon-webapp-close" />
              </button>
            </header>

            <div className="position-relative z-index-1">
              <div className="padding-x-sm padding-x-0@sm">
                {this.props.children}
              </div>
            </div>
          </div>
        </aside>
      </>
    )
  }
}

export default ResponsiveSidebar
