import React from "react"
import { Link, withPrefix } from "gatsby"

class SidenavNavigation extends React.Component {
  componentDidMount() {
    let sidenavNavigation = document.createElement("script")
    sidenavNavigation.src = `${withPrefix(
      "scripts/sidenav-navigation-script.js"
    )}`
    sidenavNavigation.id = "sidenav-navigation-script-js"
    document.body.appendChild(sidenavNavigation)
  }

  componentWillUnmount() {
    document.getElementById("sidenav-navigation-script-js").remove()
  }

  render() {
    // const isActive = ({ isCurrent }) => {
    //   return isCurrent ? { className: "chivato" } : {}
    // }

    return (
      <nav className="sidenav js-sidenav">
        <ul className="sidenav__list">
          {this.props.sidenavNavigationListItems.map(sidenavNavigationItem => (
            <li
              className={`sidenav__item ${
                sidenavNavigationItem.subItems != null
                  ? "sidenav__item--has-sublist"
                  : ""
              }`}
              key={sidenavNavigationItem.url}
            >
              <Link className="sidenav__link" to={sidenavNavigationItem.url}>
                <span>{sidenavNavigationItem.title}</span>
              </Link>

              {sidenavNavigationItem.subItems != null && (
                <>
                  <button
                    className="reset sidenav__sublist-control js-sidenav__sublist-control js-tab-focus"
                    aria-label="Abrir Submenú"
                  >
                    <svg className="icon" viewBox="0 0 12 12">
                      <polygon points="4 3 8 6 4 9 4 3" />
                    </svg>
                  </button>
                  <ul className="sidenav__list">
                    {sidenavNavigationItem.subItems.map(
                      sidenavNavigationSubItem => (
                        <li
                          className={`sidenav__item `}
                          key={sidenavNavigationSubItem.url}
                        >
                          <Link
                            className="sidenav__link"
                            to={sidenavNavigationSubItem.url}
                            // getProps={isActive}
                          >
                            <span>{sidenavNavigationSubItem.title}</span>
                          </Link>
                        </li>
                      )
                    )}
                  </ul>
                </>
              )}
            </li>
          ))}
        </ul>
      </nav>
    )
  }
}

export default SidenavNavigation
