import React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../components/Layout"
import ResponsiveSidebar from "../components/ResponsiveSidebar/ResponsiveSidebar"
import SidenavNavigation from "../components/SidenavNavigation/SidenavNavigation"

export const query = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      excerpt
      html
      frontmatter {
        title
      }
    }
  }
`

const CanalizacionAguasTemplate = ({
  data: { markdownRemark: canalizacionAguas },
}) => {
  const sidenavNavigationListItems = [
    {
      title: "Tuberías en PVC",
      url: "/canalizacion-aguas/tuberias-pvc",
    },
    {
      title: "Tuberías en Polietileno",
      url: "/canalizacion-aguas/tuberias-polietileno",
    },
    {
      title: "Tuberías Portagoteros P.E.",
      url: "/canalizacion-aguas/tuberias-portagoteros",
    },
    {
      title: "Tuberías de Acero",
      url: "/canalizacion-aguas/tuberias-acero",
    },
    {
      title: "Tuberías de Plástico Flexible",
      url: "/canalizacion-aguas/tuberias-plastico-flexible",
    },
    {
      title: "Tuberías Especiales",
      url: "/canalizacion-aguas/tuberias-especiales",
    },
  ]

  return (
    <Layout>
      <div className="container">
        <div className="margin-y-lg">
          <div className="grid gap-xl">
            <div className="col-12">
              <h1 className="text-md">
                <Link className="text-decoration-none" to="/canalizacion-aguas">
                  Canalización de aguas
                </Link>
              </h1>
            </div>
            <div className="col-12 col-4@sm">
              <ResponsiveSidebar title="Canalización de aguas">
                <SidenavNavigation
                  sidenavNavigationListItems={sidenavNavigationListItems}
                />
              </ResponsiveSidebar>
            </div>
            <div className="col-12 col-8@sm">
              <div className="margin-y-sm text-component">
                <h2 className="text-sm">
                  {canalizacionAguas.frontmatter.title}
                </h2>
                <div
                  dangerouslySetInnerHTML={{ __html: canalizacionAguas.html }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default CanalizacionAguasTemplate
